<template>
<div>
	<div class="titaui-dynamic-list__item-wrapper" style="opacity: 1;">
		<div class="titaui-dynamic-item">
			<div class="titaui-dynamic-item__content" style="height: auto;">
				<div class="titaui-dynamic-project-item">
					<div class="titaui-dynamic-item-header">
						<span class="titaui-dynamic-item-header-left">
							<span class="titaui-dynamic-item-header-desc-avatar titaui-dynamic-item-header-desc-avatar--not-link">
								<div class="titaui-pc-avator-container">
									<div class="titaui-pc-avator__delete-wrapper ">
										
									</div>
									<div class="titaui-pc-avator-wrapper">
										<div class="titaui-pc-avatar--text" style="width: 48px; height: 48px; background-color: rgb(245, 177, 104); font-size: 18px;">
											张
										</div>
									</div>
								</div>
							</span>
							<span class="titaui-dynamic-item-header-desc">
								<div class="titaui-dynamic-item-header-desc-title">
									<div class="titaui-dynamic-item-header-desc-title-left">
										<span class="titaui-dynamic-item-header-desc-user titaui-dynamic-item-header-desc-user--not-link">
											<span class="tooltip-text-wrapper undefined">
												张天哲
											</span>
											<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
												张天哲
											</span>
										</span>
										<span class="titaui-dynamic-project-item__operate-desc">
											负责了项目
										</span>
									</div>
									<div class="titaui-dynamic-item-header__more-operate">
										<span class="titaui-dynamic-item-header-right tu-icon-more1">
										</span>
									</div>
								</div>
								<div class="titaui-dynamic-item-header-desc-time">
									6月19日 23:05
								</div>
							</span>
						</span>
					</div>
					<div class="titaui-dynamic-project-item__content">
						<div class="titaui-dynamic-project-item__content-info">
							<div class="titaui-dynamic-project-item__content-title">
								<span class="titaui-dynamic-project-item__content-title-type">
									项目
								</span>
								<span class="titaui-dynamic-project-item__content-title-text">
									<span class="tooltip-text-wrapper undefined">
										<span class="titaui-dynamic-project-item__content-title-text">
											<span>
												<span>
													test
												</span>
											</span>
										</span>
									</span>
									<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
										<span class="titaui-dynamic-project-item__content-title-text">
											<span>
												<span>
													test
												</span>
											</span>
										</span>
									</span>
									<span style="display: inline-block; width: 100%;">
									</span>
								</span>
							</div>
							<div class="titaui-dynamic-project-item__content-status-and-time-range">
								<i class="tu-icon-running titaui-dynamic-project-item__content-status-icon">
								</i>
								<span class="titaui-dynamic-project-item__content-status-text">
									进行中
								</span>
								<i class="tu-icon-deferred titaui-dynamic-project-item__content-status-icon">
								</i>
								<span class="titaui-dynamic-project-item__content-time-range">
									2022/06/19-2022/07/18
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="titaui-dynamic-operation__operate">
				<div class="titaui-dynamic-operation__operate-item">
					<div class="titaui-dynamic-operation__operate-item-left">
						<img class="titaui-dynamic-operation__operate-icon" :src="require('@/assets/dynamic-operate-4013df51436ab640e98fe2eabf4ee59e.png')"alt=""/>
						<span class="titaui-dynamic-operation__operate-time">
							2022/06/19 23:05
						</span>
					</div>
					<div class="titaui-dynamic-operation__operate-item-right">
						<span class="titaui-dynamic-operation__operate-user">
							<span class="tooltip-text-wrapper undefined">
								张天哲
							</span>
							<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
								张天哲
							</span>
						</span>
						<span class="titaui-dynamic-operation__operate-content">
							<span>
								创建了项目
							</span>
						</span>
					</div>
				</div>
			</div>
			
		</div>
	</div>
	<div class="titaui-dynamic-list__item-wrapper" style="opacity: 1;">
		<div class="titaui-dynamic-item">
			<div class="titaui-dynamic-item__content" style="height: auto;">
				<div class="titaui-dynamic-task-item">
					<div class="titaui-dynamic-item-header">
						<span class="titaui-dynamic-item-header-left">
							<span class="titaui-dynamic-item-header-desc-avatar titaui-dynamic-item-header-desc-avatar--not-link">
								<div class="titaui-pc-avator-container">
									<div class="titaui-pc-avator__delete-wrapper ">
										
									</div>
									<div class="titaui-pc-avator-wrapper">
										<div class="titaui-pc-avatar--text" style="width: 48px; height: 48px; background-color: rgb(245, 177, 104); font-size: 18px;">
											张
										</div>
									</div>
								</div>
							</span>
							<span class="titaui-dynamic-item-header-desc">
								<div class="titaui-dynamic-item-header-desc-title">
									<div class="titaui-dynamic-item-header-desc-title-left">
										<span class="titaui-dynamic-item-header-desc-user titaui-dynamic-item-header-desc-user--not-link">
											<span class="tooltip-text-wrapper undefined">
												张天哲
											</span>
											<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
												张天哲
											</span>
										</span>
										<span class="titaui-dynamic-task-item__operate-desc">
											<span class="titaui-dynamic-task-item__operate-desc-wrapper">
												负责了看板
												<span class="titaui-dynamic-task-item__object-name">
													<span class="tooltip-text-wrapper undefined">
														<span>
															12345
														</span>
													</span>
													<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
														<span>
															12345
														</span>
													</span>
													<span style="display: inline-block; width: 100%;">
													</span>
												</span>
												下的任务
											</span>
										</span>
									</div>
									<div class="titaui-dynamic-item-header__more-operate">
										<span class="titaui-dynamic-item-header-right tu-icon-more1">
										</span>
									</div>
								</div>
								<div class="titaui-dynamic-item-header-desc-time">
									6月19日 22:51
								</div>
							</span>
						</span>
					</div>
					<div class="titaui-dynamic-task-item__content">
						<div class="titaui-dynamic-task-item__content-info">
							<div class="titaui-dynamic-task-item__content-title">
								<span class="titaui-dynamic-task-item__content-title-type">
									任务
								</span>
								<span class="titaui-dynamic-task-item__content-title-text">
									<span class="tooltip-text-wrapper undefined">
										<span class="titaui-dynamic-task-item__content-title-text">
											<span>
												<span>
													12345
												</span>
											</span>
										</span>
									</span>
									<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
										<span class="titaui-dynamic-task-item__content-title-text">
											<span>
												<span>
													12345
												</span>
											</span>
										</span>
									</span>
									<span style="display: inline-block; width: 100%;">
									</span>
								</span>
							</div>
							<div class="titaui-dynamic-task-item__content-status-and-time-range">
								<i class="tu-icon-running titaui-dynamic-task-item__content-status-icon">
								</i>
								<span class="titaui-dynamic-task-item__content-status-text">
									进行中
								</span>
								<i class="tu-icon-deferred titaui-dynamic-task-item__content-status-icon">
								</i>
								<span class="titaui-dynamic-task-item__content-time-range">
									2022/06/19 08:00-2022/06/19 18:00
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="titaui-dynamic-operation__operate">
				<div class="titaui-dynamic-operation__operate-item">
					<div class="titaui-dynamic-operation__operate-item-left">
						<img class="titaui-dynamic-operation__operate-icon" :src="require('@/assets/dynamic-operate-4013df51436ab640e98fe2eabf4ee59e.png')"alt=""/>
						<span class="titaui-dynamic-operation__operate-time">
							2022/06/19 22:51
						</span>
					</div>
					<div class="titaui-dynamic-operation__operate-item-right">
						<span class="titaui-dynamic-operation__operate-user">
							<span class="tooltip-text-wrapper undefined">
								张天哲
							</span>
							<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
								张天哲
							</span>
						</span>
						<span class="titaui-dynamic-operation__operate-content">
							<span>
								启动了任务
							</span>
						</span>
					</div>
				</div>
			</div>
			
		</div>
	</div>
	<div class="titaui-dynamic-list__item-wrapper" style="opacity: 1;">
		<div class="titaui-dynamic-item">
			<div class="titaui-dynamic-item__content" style="height: auto;">
				<div class="titaui-dynamic-create-o-item">
					<div class="titaui-dynamic-item-header">
						<span class="titaui-dynamic-item-header-left">
							<span class="titaui-dynamic-item-header-desc-avatar titaui-dynamic-item-header-desc-avatar--not-link">
								<div class="titaui-pc-avator-container">
									<div class="titaui-pc-avator__delete-wrapper ">
										
									</div>
									<div class="titaui-pc-avator-wrapper">
										<div class="titaui-pc-avatar--text" style="width: 48px; height: 48px; background-color: rgb(245, 177, 104); font-size: 18px;">
											张
										</div>
									</div>
								</div>
							</span>
							<span class="titaui-dynamic-item-header-desc">
								<div class="titaui-dynamic-item-header-desc-title">
									<div class="titaui-dynamic-item-header-desc-title-left">
										<span class="titaui-dynamic-item-header-desc-user titaui-dynamic-item-header-desc-user--not-link">
											<span class="tooltip-text-wrapper undefined">
												张天哲
											</span>
											<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
												张天哲
											</span>
										</span>
										<span class="titaui-dynamic-create-o-item__operate-desc">
											负责了目标
										</span>
									</div>
									<div class="titaui-dynamic-item-header__more-operate">
										<span class="titaui-dynamic-item-header-right tu-icon-more1">
										</span>
									</div>
								</div>
								<div class="titaui-dynamic-item-header-desc-time">
									6月9日 12:38
								</div>
							</span>
						</span>
					</div>
					<div class="titaui-dynamic-create-o-item__content">
						<div class="titaui-dynamic-create-o-item__content-left">
							<span class="titaui-progress-circle">
								<svg width="48" height="48" viewBox="0 0 48 48">
									<circle cx="24" cy="24" r="19" fill="none" stroke="rgba(40,121,255,0.11)"
									stroke-width="5">
									</circle>
									<circle cx="24" cy="24" r="19" fill="none" stroke="url(#544736_9381039_62_101_637903751263257183)"
									stroke-width="5" stroke-dasharray="119" stroke-dashoffset="119" stroke-linecap="round"
									transform="rotate(-90, 24 24)" style="transition: stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s ease 0s, stroke-width 0.06s ease 0.3s;">
									</circle>
									<defs>
										<linearGradient x1="96.9769965%" y1="80.9878035%" x2="0%" y2="41.7547666%"
										id="544736_9381039_62_101_637903751263257183">
											<stop stop-color="#4E90FF" offset="0%">
											</stop>
											<stop stop-color="#2879ff" offset="100%">
											</stop>
										</linearGradient>
									</defs>
								</svg>
								<span class="tooltip-text-wrapper undefined">
									<div class="titaui-dynamic-create-o-item__content-progress-text text-content"
									style="margin-left: 2px; color: rgb(137, 145, 159);">
										0%
									</div>
								</span>
								<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
									<div class="titaui-dynamic-create-o-item__content-progress-text text-content"
									style="margin-left: 2px; color: rgb(137, 145, 159);">
										0%
									</div>
								</span>
							</span>
						</div>
						<div class="titaui-dynamic-create-o-item__content-right">
							<div class="titaui-dynamic-create-o-item__content-right-title">
								<span class="titaui-dynamic-create-o-item__content-right-title-text">
									<span class="tooltip-text-wrapper undefined">
										<span>
											<span>
												形成良好的产品口碑效应，获得客户转介绍
											</span>
										</span>
									</span>
									<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
										<span>
											<span>
												形成良好的产品口碑效应，获得客户转介绍
											</span>
										</span>
									</span>
									<span style="display: inline-block; width: 100%;">
									</span>
								</span>
							</div>
							<div class="titaui-dynamic-create-o-item__content-right-infos-wrapper">
								<div class="titaui-dynamic-create-o-item__okr">
									<span class="titaui-dynamic-create-o-item__okr-type">
										个人
									</span>
									<span class="titaui-dynamic-create-o-item__okr-cycle">
										2022年第二季度 (2022/04/01-2022/06/30)
									</span>
								</div>
								<div class="titaui-dynamic-create-o-item__okr-krs">
									<div class="titaui-dynamic-create-o-item__kr">
										<span class="titaui-dynamic-create-o-item__kr-order">
											KR1
										</span>
										<span class="titaui-dynamic-create-o-item__kr-name">
											<span>
												<span>
													调研出10条有价值的客户正面、负面评价信息
												</span>
											</span>
										</span>
									</div>
									<div class="titaui-dynamic-create-o-item__kr">
										<span class="titaui-dynamic-create-o-item__kr-order">
											KR2
										</span>
										<span class="titaui-dynamic-create-o-item__kr-name">
											<span>
												<span>
													实现0bug上线，提升用户体验
												</span>
											</span>
										</span>
									</div>
									<div class="titaui-dynamic-create-o-item__kr">
										<span class="titaui-dynamic-create-o-item__kr-order">
											KR3
										</span>
										<span class="titaui-dynamic-create-o-item__kr-name">
											<span>
												<span>
													客户好评率提升至90%
												</span>
											</span>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="titaui-dynamic-operation__operate">
				<div class="titaui-dynamic-operation__operate-item">
					<div class="titaui-dynamic-operation__operate-item-left">
						 <img class="titaui-dynamic-operation__operate-icon" :src="require('@/assets/dynamic-operate-4013df51436ab640e98fe2eabf4ee59e.png')"alt=""/>
						<span class="titaui-dynamic-operation__operate-time">
							2022/06/09 12:55
						</span>
					</div>
					<div class="titaui-dynamic-operation__operate-item-right">
						<span class="titaui-dynamic-operation__operate-user">
							<span class="tooltip-text-wrapper undefined">
								程序主管
							</span>
							<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
								程序主管
							</span>
						</span>
						<span class="titaui-dynamic-operation__operate-content">
							<span>
								删除了任务：aaa
							</span>
						</span>
					</div>
				</div>
				<div class="titaui-dynamic-operation__operate-item">
					<div class="titaui-dynamic-operation__operate-item-left">
						 <img class="titaui-dynamic-operation__operate-icon" :src="require('@/assets/dynamic-operate-4013df51436ab640e98fe2eabf4ee59e.png')"alt=""/>
						<span class="titaui-dynamic-operation__operate-time">
							2022/06/09 12:55
						</span>
					</div>
					<div class="titaui-dynamic-operation__operate-item-right">
						<span class="titaui-dynamic-operation__operate-user">
							<span class="tooltip-text-wrapper undefined">
								程序主管
							</span>
							<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
								程序主管
							</span>
						</span>
						<span class="titaui-dynamic-operation__operate-content">
							<span>
								删除了任务：test
							</span>
						</span>
					</div>
				</div>
				<div class="titaui-dynamic-operation__operate-item">
					<div class="titaui-dynamic-operation__operate-item-left">
						 <img class="titaui-dynamic-operation__operate-icon" :src="require('@/assets/dynamic-operate-4013df51436ab640e98fe2eabf4ee59e.png')"alt=""/>
						<span class="titaui-dynamic-operation__operate-time">
							2022/06/13 18:54
						</span>
					</div>
					<div class="titaui-dynamic-operation__operate-item-right">
						<span class="titaui-dynamic-operation__operate-user">
							<span class="tooltip-text-wrapper undefined">
								会计主管
							</span>
							<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
								会计主管
							</span>
						</span>
						<span class="titaui-dynamic-operation__operate-content">
							<span>
								将目标负责人转交给 张天哲
							</span>
						</span>
					</div>
				</div>
				<div class="titaui-dynamic-operation__operate-item">
					 <img class="titaui-dynamic-operation__operate-icon" :src="require('@/assets/dynamic-operate-4013df51436ab640e98fe2eabf4ee59e.png')"alt=""/>
					<span class="titaui-dynamic-operation__operate-item-total">
						全部操作
						<span class="titaui-dynamic-operation__operate-item-total-num">
							7
						</span>
						<i class="tu-icon-APP-xi">
						</i>
					</span>
				</div>
			</div>
			
		</div>
	</div>
	<div class="titaui-dynamic-list__item-wrapper" style="opacity: 1;">
		<div class="titaui-dynamic-item">
			<div class="titaui-dynamic-item__content" style="height: auto;">
				<div class="titaui-dynamic-reply-item">
					<div class="titaui-dynamic-item-header">
						<span class="titaui-dynamic-item-header-left">
							<span class="titaui-dynamic-item-header-desc-avatar titaui-dynamic-item-header-desc-avatar--not-link">
								<div class="titaui-pc-avator-container">
									<div class="titaui-pc-avator__delete-wrapper ">
										
									</div>
									<div class="titaui-pc-avator-wrapper">
										<div class="titaui-pc-avatar--text" style="width: 48px; height: 48px; background-color: rgb(161, 165, 238); font-size: 18px;">
											程
										</div>
									</div>
								</div>
							</span>
							<span class="titaui-dynamic-item-header-desc">
								<div class="titaui-dynamic-item-header-desc-title">
									<div class="titaui-dynamic-item-header-desc-title-left">
										<span class="titaui-dynamic-item-header-desc-user titaui-dynamic-item-header-desc-user--not-link">
											<span class="tooltip-text-wrapper undefined">
												程序主管
											</span>
											<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
												程序主管
											</span>
										</span>
										<span class="titaui-dynamic-reply-item__operate-desc">
											的目标复盘
										</span>
									</div>
									<div class="titaui-dynamic-item-header__more-operate">
										<span class="titaui-dynamic-item-header-right tu-icon-more1">
										</span>
									</div>
								</div>
								<div class="titaui-dynamic-item-header-desc-time">
									6月13日 17:09
								</div>
							</span>
						</span>
					</div>
					<div class="titaui-dynamic-reply-item__content">
						<div class="titaui-dynamic-reply-item__content-title">
							<div class="titaui-dynamic-title" style="background-color: rgba(40, 121, 255, 0.1);">
								<span class="titaui-dynamic-title__type" style="background-color: rgba(40, 121, 255, 0.5);">
									目标
								</span>
								<span class="titaui-dynamic-title__content">
									<span class="titaui-dynamic-title__content-text" style="color: rgb(40, 121, 255);">
										<span class="tooltip-text-wrapper undefined">
											<span>
												<span>
													建立一流的研发团队，以满足增长需要
												</span>
											</span>
										</span>
										<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
											<span>
												<span>
													建立一流的研发团队，以满足增长需要
												</span>
											</span>
										</span>
										<span style="display: inline-block; width: 100%;">
										</span>
									</span>
								</span>
							</div>
						</div>
						<div class="titaui-dynamic-reply-item__detail">
							<div class="titaui-dynamic-reply-item__detail-gain">
								<div class="titaui-dynamic-reply-item__detail-block-title">
									价值与收获
								</div>
								<div class="titaui-dynamic-reply-item__detail-block-content">
									<span>
										<span>
											上级复盘
										</span>
									</span>
								</div>
							</div>
							<div>
								<div class="titaui-dynamic-reply-item__detail-drawback">
									<div class="titaui-dynamic-reply-item__detail-block-title">
										问题与不足 1
									</div>
									<div class="titaui-dynamic-reply-item__detail-block-content">
										<span>
											<span>
											</span>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<span>
			</span>
			
		</div>
	</div>
	<div class="titaui-dynamic-list__item-wrapper" style="opacity: 1;">
		<div class="titaui-dynamic-item">
			<div class="titaui-dynamic-item__content" style="height: auto;">
				<div class="titaui-dynamic-task-item">
					<div class="titaui-dynamic-item-header">
						<span class="titaui-dynamic-item-header-left">
							<span class="titaui-dynamic-item-header-desc-avatar titaui-dynamic-item-header-desc-avatar--not-link">
								<div class="titaui-pc-avator-container">
									<div class="titaui-pc-avator__delete-wrapper ">
										
									</div>
									<div class="titaui-pc-avator-wrapper">
										<div class="titaui-pc-avatar--text" style="width: 48px; height: 48px; background-color: rgb(245, 177, 104); font-size: 18px;">
											张
										</div>
									</div>
								</div>
							</span>
							<span class="titaui-dynamic-item-header-desc">
								<div class="titaui-dynamic-item-header-desc-title">
									<div class="titaui-dynamic-item-header-desc-title-left">
										<span class="titaui-dynamic-item-header-desc-user titaui-dynamic-item-header-desc-user--not-link">
											<span class="tooltip-text-wrapper undefined">
												张天哲
											</span>
											<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
												张天哲
											</span>
										</span>
										<span class="titaui-dynamic-task-item__operate-desc">
											<span class="titaui-dynamic-task-item__operate-desc-wrapper">
												负责了目标
												<span class="titaui-dynamic-task-item__object-name">
													<span class="tooltip-text-wrapper undefined">
														<span>
															开创新绩效考核制度，打造企业持续绩效考核模式
														</span>
													</span>
													<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
														<span>
															开创新绩效考核制度，打造企业持续绩效考核模式
														</span>
													</span>
													<span style="display: inline-block; width: 100%;">
													</span>
												</span>
												下的任务
											</span>
										</span>
									</div>
									<div class="titaui-dynamic-item-header__more-operate">
										<span class="titaui-dynamic-item-header-right tu-icon-more1">
										</span>
									</div>
								</div>
								<div class="titaui-dynamic-item-header-desc-time">
									6月11日 19:59
								</div>
							</span>
						</span>
					</div>
					<div class="titaui-dynamic-task-item__content">
						<div class="titaui-dynamic-task-item__content-info">
							<div class="titaui-dynamic-task-item__content-title">
								<span class="titaui-dynamic-task-item__content-title-type">
									任务
								</span>
								<span class="titaui-dynamic-task-item__content-title-text">
									<span class="tooltip-text-wrapper undefined">
										<span class="titaui-dynamic-task-item__content-title-text">
											<span>
												<span>
													新绩效考核学习
												</span>
											</span>
										</span>
									</span>
									<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
										<span class="titaui-dynamic-task-item__content-title-text">
											<span>
												<span>
													新绩效考核学习
												</span>
											</span>
										</span>
									</span>
									<span style="display: inline-block; width: 100%;">
									</span>
								</span>
							</div>
							<div class="titaui-dynamic-task-item__content-status-and-time-range">
								<i class="tu-icon-running titaui-dynamic-task-item__content-status-icon">
								</i>
								<span class="titaui-dynamic-task-item__content-status-text">
									进行中
								</span>
								<i class="tu-icon-deferred titaui-dynamic-task-item__content-status-icon">
								</i>
								<span class="titaui-dynamic-task-item__content-time-range">
									2022/06/11 08:00-2022/06/11 18:00
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="titaui-dynamic-operation__operate">
				<div class="titaui-dynamic-operation__operate-item">
					<div class="titaui-dynamic-operation__operate-item-left">
						<img class="titaui-dynamic-operation__operate-icon" :src="require('@/assets/dynamic-operate-4013df51436ab640e98fe2eabf4ee59e.png')"alt=""/>
						<span class="titaui-dynamic-operation__operate-time">
							2022/06/11 19:59
						</span>
					</div>
					<div class="titaui-dynamic-operation__operate-item-right">
						<span class="titaui-dynamic-operation__operate-user">
							<span class="tooltip-text-wrapper undefined">
								张天哲
							</span>
							<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
								张天哲
							</span>
						</span>
						<span class="titaui-dynamic-operation__operate-content">
							<span>
								启动了任务
							</span>
						</span>
					</div>
				</div>
			</div>
			
		</div>
	</div>
	<div class="titaui-dynamic-list__item-wrapper" style="opacity: 1;">
		<div class="titaui-dynamic-item">
			<div class="titaui-dynamic-item__content" style="height: auto;">
				<div class="titaui-dynamic-create-o-item">
					<div class="titaui-dynamic-item-header">
						<span class="titaui-dynamic-item-header-left">
							<span class="titaui-dynamic-item-header-desc-avatar titaui-dynamic-item-header-desc-avatar--not-link">
								<div class="titaui-pc-avator-container">
									<div class="titaui-pc-avator__delete-wrapper ">
										
									</div>
									<div class="titaui-pc-avator-wrapper">
										<div class="titaui-pc-avatar--text" style="width: 48px; height: 48px; background-color: rgb(245, 177, 104); font-size: 18px;">
											张
										</div>
									</div>
								</div>
							</span>
							<span class="titaui-dynamic-item-header-desc">
								<div class="titaui-dynamic-item-header-desc-title">
									<div class="titaui-dynamic-item-header-desc-title-left">
										<span class="titaui-dynamic-item-header-desc-user titaui-dynamic-item-header-desc-user--not-link">
											<span class="tooltip-text-wrapper undefined">
												张天哲
											</span>
											<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
												张天哲
											</span>
										</span>
										<span class="titaui-dynamic-create-o-item__operate-desc">
											负责了目标
										</span>
									</div>
									<div class="titaui-dynamic-item-header__more-operate">
										<span class="titaui-dynamic-item-header-right tu-icon-more1">
										</span>
									</div>
								</div>
								<div class="titaui-dynamic-item-header-desc-time">
									4月13日 15:00
								</div>
							</span>
						</span>
					</div>
					<div class="titaui-dynamic-create-o-item__content">
						<div class="titaui-dynamic-create-o-item__content-left">
							<span class="titaui-progress-circle">
								<svg width="48" height="48" viewBox="0 0 48 48">
									<circle cx="24" cy="24" r="19" fill="none" stroke="rgba(40,121,255,0.11)"
									stroke-width="5">
									</circle>
									<circle cx="24" cy="24" r="19" fill="none" stroke="url(#544736_9327378_62_101_637854588157575973)"
									stroke-width="5" stroke-dasharray="119" stroke-dashoffset="95" stroke-linecap="round"
									transform="rotate(-90, 24 24)" style="transition: stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s ease 0s, stroke-width 0.06s ease 0.3s;">
									</circle>
									<defs>
										<linearGradient x1="96.9769965%" y1="80.9878035%" x2="0%" y2="41.7547666%"
										id="544736_9327378_62_101_637854588157575973">
											<stop stop-color="#4E90FF" offset="0%">
											</stop>
											<stop stop-color="#2879ff" offset="100%">
											</stop>
										</linearGradient>
									</defs>
								</svg>
								<span class="tooltip-text-wrapper undefined">
									<div class="titaui-dynamic-create-o-item__content-progress-text text-content"
									style="margin-left: 2px; color: rgb(137, 145, 159);">
										20%
									</div>
								</span>
								<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
									<div class="titaui-dynamic-create-o-item__content-progress-text text-content"
									style="margin-left: 2px; color: rgb(137, 145, 159);">
										20%
									</div>
								</span>
							</span>
						</div>
						<div class="titaui-dynamic-create-o-item__content-right">
							<div class="titaui-dynamic-create-o-item__content-right-title">
								<span class="titaui-dynamic-create-o-item__content-right-title-text">
									<span class="tooltip-text-wrapper undefined">
										<span>
											<span>
												2323
											</span>
										</span>
									</span>
									<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
										<span>
											<span>
												2323
											</span>
										</span>
									</span>
									<span style="display: inline-block; width: 100%;">
									</span>
								</span>
							</div>
							<div class="titaui-dynamic-create-o-item__content-right-infos-wrapper">
								<div class="titaui-dynamic-create-o-item__okr">
									<span class="titaui-dynamic-create-o-item__okr-type">
										个人
									</span>
									<span class="titaui-dynamic-create-o-item__okr-cycle">
										2022年第二季度 (2022/04/01-2022/06/30)
									</span>
								</div>
								<div class="titaui-dynamic-create-o-item__okr-krs">
									<div class="titaui-dynamic-create-o-item__kr">
										<span class="titaui-dynamic-create-o-item__kr-order">
											KR1
										</span>
										<span class="titaui-dynamic-create-o-item__kr-name">
											<span>
												<span>
													2
												</span>
											</span>
										</span>
									</div>
									<div class="titaui-dynamic-create-o-item__kr">
										<span class="titaui-dynamic-create-o-item__kr-order">
											KR2
										</span>
										<span class="titaui-dynamic-create-o-item__kr-name">
											<span>
												<span>
													3232323
												</span>
											</span>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="titaui-dynamic-operation__operate">
				<div class="titaui-dynamic-operation__operate-item">
					<div class="titaui-dynamic-operation__operate-item-left">
						<img class="titaui-dynamic-operation__operate-icon" :src="require('@/assets/dynamic-operate-4013df51436ab640e98fe2eabf4ee59e.png')"alt=""/>
						<span class="titaui-dynamic-operation__operate-time">
							2022/05/30 10:36
						</span>
					</div>
					<div class="titaui-dynamic-operation__operate-item-right">
						<span class="titaui-dynamic-operation__operate-user">
							<span class="tooltip-text-wrapper undefined">
								张天哲
							</span>
							<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
								张天哲
							</span>
						</span>
						<span class="titaui-dynamic-operation__operate-content">
							<span>
								更新目标完成度为 20% ,进展：正常进行
							</span>
						</span>
					</div>
				</div>
				<div class="titaui-dynamic-operation__operate-item">
					<div class="titaui-dynamic-operation__operate-item-left">
						<img class="titaui-dynamic-operation__operate-icon" :src="require('@/assets/dynamic-operate-4013df51436ab640e98fe2eabf4ee59e.png')"alt=""/>
						<span class="titaui-dynamic-operation__operate-time">
							2022/06/06 12:13
						</span>
					</div>
					<div class="titaui-dynamic-operation__operate-item-right">
						<span class="titaui-dynamic-operation__operate-user">
							<span class="tooltip-text-wrapper undefined">
								张天哲
							</span>
							<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
								张天哲
							</span>
						</span>
						<span class="titaui-dynamic-operation__operate-content">
							<span>
								删除了任务：bbb
							</span>
						</span>
					</div>
				</div>
				<div class="titaui-dynamic-operation__operate-item">
					<div class="titaui-dynamic-operation__operate-item-left">
						<img class="titaui-dynamic-operation__operate-icon" :src="require('@/assets/dynamic-operate-4013df51436ab640e98fe2eabf4ee59e.png')"alt=""/>
						<span class="titaui-dynamic-operation__operate-time">
							2022/06/09 17:38
						</span>
					</div>
					<div class="titaui-dynamic-operation__operate-item-right">
						<span class="titaui-dynamic-operation__operate-user">
							<span class="tooltip-text-wrapper undefined">
								张天哲
							</span>
							<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
								张天哲
							</span>
						</span>
						<span class="titaui-dynamic-operation__operate-content">
							<span>
								将任务 aaa 移出目标
							</span>
						</span>
					</div>
				</div>
				<div class="titaui-dynamic-operation__operate-item">
					<img class="titaui-dynamic-operation__operate-icon" :src="require('@/assets/dynamic-operate-4013df51436ab640e98fe2eabf4ee59e.png')"alt=""/>
					<span class="titaui-dynamic-operation__operate-item-total">
						全部操作
						<span class="titaui-dynamic-operation__operate-item-total-num">
							5
						</span>
						<i class="tu-icon-APP-xi">
						</i>
					</span>
				</div>
			</div>
			
		</div>
	</div>
	<div class="titaui-dynamic-list__item-wrapper" style="opacity: 1;">
		<div class="titaui-dynamic-item">
			<div class="titaui-dynamic-item__content" style="height: auto;">
				<div class="titaui-dynamic-progress-item">
					<div class="titaui-dynamic-item-header">
						<span class="titaui-dynamic-item-header-left">
							<span class="titaui-dynamic-item-header-desc-avatar titaui-dynamic-item-header-desc-avatar--not-link">
								<div class="titaui-pc-avator-container">
									<div class="titaui-pc-avator__delete-wrapper ">
										
									</div>
									<div class="titaui-pc-avator-wrapper">
										<div class="titaui-pc-avatar--text" style="width: 48px; height: 48px; background-color: rgb(245, 177, 104); font-size: 18px;">
											张
										</div>
									</div>
								</div>
							</span>
							<span class="titaui-dynamic-item-header-desc">
								<div class="titaui-dynamic-item-header-desc-title">
									<div class="titaui-dynamic-item-header-desc-title-left">
										<span class="titaui-dynamic-item-header-desc-user titaui-dynamic-item-header-desc-user--not-link">
											<span class="tooltip-text-wrapper undefined">
												张天哲
											</span>
											<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
												张天哲
											</span>
										</span>
										<span class="titaui-dynamic-progress-item__operate-desc">
											<span class="titaui-dynamic-progress-item__obj-wrapper">
												更新了目标
												<span class="titaui-dynamic-progress-item__object-name">
													<span class="tooltip-text-wrapper undefined">
														<span>
															<span>
																2323
															</span>
														</span>
													</span>
													<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
														<span>
															<span>
																2323
															</span>
														</span>
													</span>
													<span style="display: inline-block; width: 100%;">
													</span>
												</span>
												的关键成果进展
											</span>
										</span>
									</div>
									<div class="titaui-dynamic-item-header__more-operate">
										<span class="titaui-dynamic-item-header-right tu-icon-more1">
										</span>
									</div>
								</div>
								<div class="titaui-dynamic-item-header-desc-time">
									6月11日 19:34
								</div>
							</span>
						</span>
					</div>
					<div class="titaui-dynamic-progress-item__content">
						<div class="titaui-dynamic-progress-item__title">
							<div class="titaui-dynamic-title" style="background-color: rgba(90, 216, 166, 0.1);">
								<span class="titaui-dynamic-title__type" style="background-color: rgba(90, 216, 166, 0.5);">
									KR
								</span>
								<span class="titaui-dynamic-title__content">
									<span class="titaui-dynamic-title__content-text" style="color: rgb(90, 216, 166);">
										<span class="tooltip-text-wrapper undefined">
											<span>
												<span>
													2
												</span>
											</span>
										</span>
										<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
											<span>
												<span>
													2
												</span>
											</span>
										</span>
										<span style="display: inline-block; width: 100%;">
										</span>
									</span>
								</span>
							</div>
						</div>
						<div class="titaui-dynamic-progress-item__status">
							<span class="titaui-dynamic-progress-item__status-text titaui-dynamic-obj-status"
							style="color: rgb(40, 121, 255);">
								正常推进
							</span>
							<span class="titaui-dynamic-progress-item__status-percent titaui-dynamic-percent">
								33%
								<span class="titaui-dynamic-percent__grow titaui-dynamic-percent__grow--increase">
									<i class="tu-icon-simple-sort-up">
									</i>
									33%
								</span>
							</span>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
	<div class="titaui-dynamic-list__item-wrapper" style="opacity: 1;">
		<div class="titaui-dynamic-item">
			<div class="titaui-dynamic-item__content" style="height: auto;">
				<div class="titaui-dynamic-create-o-item">
					<div class="titaui-dynamic-item-header">
						<span class="titaui-dynamic-item-header-left">
							<span class="titaui-dynamic-item-header-desc-avatar titaui-dynamic-item-header-desc-avatar--not-link">
								<div class="titaui-pc-avator-container">
									<div class="titaui-pc-avator__delete-wrapper ">
										
									</div>
									<div class="titaui-pc-avator-wrapper">
										<div class="titaui-pc-avatar--text" style="width: 48px; height: 48px; background-color: rgb(245, 177, 104); font-size: 18px;">
											张
										</div>
									</div>
								</div>
							</span>
							<span class="titaui-dynamic-item-header-desc">
								<div class="titaui-dynamic-item-header-desc-title">
									<div class="titaui-dynamic-item-header-desc-title-left">
										<span class="titaui-dynamic-item-header-desc-user titaui-dynamic-item-header-desc-user--not-link">
											<span class="tooltip-text-wrapper undefined">
												张天哲
											</span>
											<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
												张天哲
											</span>
										</span>
										<span class="titaui-dynamic-create-o-item__operate-desc">
											负责了目标
										</span>
									</div>
									<div class="titaui-dynamic-item-header__more-operate">
										<span class="titaui-dynamic-item-header-right tu-icon-more1">
										</span>
									</div>
								</div>
								<div class="titaui-dynamic-item-header-desc-time">
									6月2日 04:19
								</div>
							</span>
						</span>
					</div>
					<div class="titaui-dynamic-create-o-item__content">
						<div class="titaui-dynamic-create-o-item__content-left">
							<span class="titaui-progress-circle">
								<svg width="48" height="48" viewBox="0 0 48 48">
									<circle cx="24" cy="24" r="19" fill="none" stroke="rgba(40,121,255,0.11)"
									stroke-width="5">
									</circle>
									<circle cx="24" cy="24" r="19" fill="none" stroke="url(#544736_9374805_62_101_637897403544655466)"
									stroke-width="5" stroke-dasharray="119" stroke-dashoffset="113" stroke-linecap="round"
									transform="rotate(-90, 24 24)" style="transition: stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s ease 0s, stroke-width 0.06s ease 0.3s;">
									</circle>
									<defs>
										<linearGradient x1="96.9769965%" y1="80.9878035%" x2="0%" y2="41.7547666%"
										id="544736_9374805_62_101_637897403544655466">
											<stop stop-color="#4E90FF" offset="0%">
											</stop>
											<stop stop-color="#2879ff" offset="100%">
											</stop>
										</linearGradient>
									</defs>
								</svg>
								<span class="tooltip-text-wrapper undefined">
									<div class="titaui-dynamic-create-o-item__content-progress-text text-content"
									style="margin-left: 2px; color: rgb(137, 145, 159);">
										5%
									</div>
								</span>
								<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
									<div class="titaui-dynamic-create-o-item__content-progress-text text-content"
									style="margin-left: 2px; color: rgb(137, 145, 159);">
										5%
									</div>
								</span>
							</span>
						</div>
						<div class="titaui-dynamic-create-o-item__content-right">
							<div class="titaui-dynamic-create-o-item__content-right-title">
								<span class="titaui-dynamic-create-o-item__content-right-title-text">
									<span class="tooltip-text-wrapper undefined">
										<span>
											<span>
												提升数据安全到80%，确保系统安全性
											</span>
										</span>
									</span>
									<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
										<span>
											<span>
												提升数据安全到80%，确保系统安全性
											</span>
										</span>
									</span>
									<span style="display: inline-block; width: 100%;">
									</span>
								</span>
							</div>
							<div class="titaui-dynamic-create-o-item__content-right-infos-wrapper">
								<div class="titaui-dynamic-create-o-item__okr">
									<span class="titaui-dynamic-create-o-item__okr-type">
										个人
									</span>
									<span class="titaui-dynamic-create-o-item__okr-cycle">
										2022年第二季度 (2022/04/01-2022/06/30)
									</span>
								</div>
								<div class="titaui-dynamic-create-o-item__okr-krs">
									<div class="titaui-dynamic-create-o-item__kr">
										<span class="titaui-dynamic-create-o-item__kr-order">
											KR1
										</span>
										<span class="titaui-dynamic-create-o-item__kr-name">
											<span>
												<span>
													至少了解5家其他公司的安全策略
												</span>
											</span>
										</span>
									</div>
									<div class="titaui-dynamic-create-o-item__kr">
										<span class="titaui-dynamic-create-o-item__kr-order">
											KR2
										</span>
										<span class="titaui-dynamic-create-o-item__kr-name">
											<span>
												<span>
													使用至少2个不同的软件包进行外部渗透测试
												</span>
											</span>
										</span>
									</div>
									<div class="titaui-dynamic-create-o-item__kr">
										<span class="titaui-dynamic-create-o-item__kr-order">
											KR3
										</span>
										<span class="titaui-dynamic-create-o-item__kr-name">
											<span>
												<span>
													提高修补漏洞的速度达到之前的150%
												</span>
											</span>
										</span>
									</div>
									<div class="titaui-dynamic-create-o-item__kr">
										<span class="titaui-dynamic-create-o-item__kr-order">
											KR4
										</span>
										<span class="titaui-dynamic-create-o-item__kr-name">
											<span>
												<span>
													保证正常运行时间和99.95％的可用性
												</span>
											</span>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="titaui-dynamic-operation__operate">
				<div class="titaui-dynamic-operation__operate-item">
					<div class="titaui-dynamic-operation__operate-item-left">
						<img class="titaui-dynamic-operation__operate-icon" :src="require('@/assets/dynamic-operate-4013df51436ab640e98fe2eabf4ee59e.png')"alt=""/>
						<span class="titaui-dynamic-operation__operate-time">
							2022/06/09 17:36
						</span>
					</div>
					<div class="titaui-dynamic-operation__operate-item-right">
						<span class="titaui-dynamic-operation__operate-user">
							<span class="tooltip-text-wrapper undefined">
								张天哲
							</span>
							<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
								张天哲
							</span>
						</span>
						<span class="titaui-dynamic-operation__operate-content">
							<span>
								将目标可见范围 全公司 修改为 仅相关成员
							</span>
						</span>
					</div>
				</div>
				<div class="titaui-dynamic-operation__operate-item">
					<div class="titaui-dynamic-operation__operate-item-left">
						<img class="titaui-dynamic-operation__operate-icon" :src="require('@/assets/dynamic-operate-4013df51436ab640e98fe2eabf4ee59e.png')"alt=""/>
						<span class="titaui-dynamic-operation__operate-time">
							2022/06/09 17:37
						</span>
					</div>
					<div class="titaui-dynamic-operation__operate-item-right">
						<span class="titaui-dynamic-operation__operate-user">
							<span class="tooltip-text-wrapper undefined">
								张天哲
							</span>
							<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
								张天哲
							</span>
						</span>
						<span class="titaui-dynamic-operation__operate-content">
							<span>
								将 程序主管 添加为目标关注者
							</span>
						</span>
					</div>
				</div>
				<div class="titaui-dynamic-operation__operate-item">
					<div class="titaui-dynamic-operation__operate-item-left">
						<img class="titaui-dynamic-operation__operate-icon" :src="require('@/assets/dynamic-operate-4013df51436ab640e98fe2eabf4ee59e.png')"alt=""/>
						<span class="titaui-dynamic-operation__operate-time">
							2022/06/09 17:50
						</span>
					</div>
					<div class="titaui-dynamic-operation__operate-item-right">
						<span class="titaui-dynamic-operation__operate-user">
							<span class="tooltip-text-wrapper undefined">
								程序主管
							</span>
							<span class="tooltip-text-wrapper tooltip-text-wrapper--hidden undefined">
								程序主管
							</span>
						</span>
						<span class="titaui-dynamic-operation__operate-content">
							<span>
								将目标 加快开发团队的速度，以满足增长需要 对齐了目标
							</span>
						</span>
					</div>
				</div>
				<div class="titaui-dynamic-operation__operate-item">
					<img class="titaui-dynamic-operation__operate-icon" :src="require('@/assets/dynamic-operate-4013df51436ab640e98fe2eabf4ee59e.png')"alt=""/>
					<span class="titaui-dynamic-operation__operate-item-total">
						全部操作
						<span class="titaui-dynamic-operation__operate-item-total-num">
							9
						</span>
						<i class="tu-icon-APP-xi">
						</i>
					</span>
				</div>
			</div>
			
		</div>
	</div>







</div>
</template>
<script>
  export default {
      name: 'AboutMeDynamic',
      components: {},
      data() {
        return {

        }
      }

  };
</script>
<style scoped>





.titaui-dynamic-list__item-wrapper {
    margin-bottom: 10px
}

.titaui-dynamic-item {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 8px 0 rgba(127,145,180,.1);
    box-sizing: border-box;
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei;
    overflow: hidden
}

.titaui-dynamic-item__content {
    margin-right: 20px;
    overflow: hidden;
    position: relative
}
.titaui-dynamic-item__content-show-more {
    align-items: flex-end;
    background: linear-gradient(180deg,hsla(0,0%,100%,0),#fff);
    border-radius: 1px;
    bottom: 0;
    box-sizing: border-box;
    color: #2879ff;
    display: flex;
    font-size: 14px;
    height: 64px;
    justify-content: center;
    line-height: 22px;
    padding-left: 69px;
    position: absolute;
    width: 100%;
    z-index: 2
}

.titaui-dynamic-item__content-show-more:hover {
    color: #5c8eff;
    cursor: pointer
}

.titaui-dynamic-item__footer {
    align-items: center;
    border-bottom: 1px solid #f0f4fa;
    border-radius: 0 0 6px 6px;
    color: #89919f;
    display: flex;
    height: 54px;
    justify-content: space-between;
    margin: 0 24px 0 88px;
    position: relative
}

.titaui-dynamic-item__footer-left {
    display: flex
}

.titaui-dynamic-item__footer-left-item {
    margin-right: 12px
}

.titaui-dynamic-item__footer-right {
    align-items: center;
    display: flex;
    justify-content: flex-end
}

.titaui-dynamic-item__footer-fold {
    align-items: center;
    bottom: 0;
    color: #2879ff;
    display: flex;
    font-size: 14px;
    height: 100%;
    justify-content: center;
    left: 50%;
    line-height: 22px;
    position: absolute;
    transform: translateX(-50%)
}

.titaui-dynamic-item__footer-fold:hover {
    color: #5c8eff;
    cursor: pointer
}

.titaui-dynamic-item__footer-icon {
    align-items: center;
    color: #a4acb9;
    cursor: pointer;
    display: inline-flex;
    font-size: 20px;
    margin-right: 30px;
    user-select: none
}

.titaui-dynamic-item__footer-icon:last-of-type {
    margin-right: 0
}

.titaui-dynamic-item__footer-icon:hover {
    color: #2879ff
}

.titaui-dynamic-item__footer-icon-redenvelopes:before {
    background: url(//xfile6.tita.com/ux/tita-dynamic/release/dist/images/dynamic-reward-normal-cd51dd0f8c3111b0830a9f652c5709db.svg) no-repeat;
    background-size: 20px 20px;
    content: "";
    display: inline-block;
    height: 20px;
    width: 20px
}

.titaui-dynamic-item__footer-icon-redenvelopes:hover {
    animation: redenvelopesRubberBand 1s
}

.titaui-dynamic-item__footer-icon-redenvelopes:hover:before {
    background: url(//xfile6.tita.com/ux/tita-dynamic/release/dist/images/dynamic-reward-hover-9b4457cd6d017bdbfed7ffdd227b0aab.svg) no-repeat
}

.titaui-dynamic-item__footer-number {
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei;
    font-size: 14px;
    font-weight: 600;
    margin-left: 8px
}
.titaui-dynamic-item__content {
    margin-right: 20px;
    overflow: hidden;
    position: relative
}

.titaui-dynamic-item__content-show-more {
    align-items: flex-end;
    background: linear-gradient(180deg,hsla(0,0%,100%,0),#fff);
    border-radius: 1px;
    bottom: 0;
    box-sizing: border-box;
    color: #2879ff;
    display: flex;
    font-size: 14px;
    height: 64px;
    justify-content: center;
    line-height: 22px;
    padding-left: 69px;
    position: absolute;
    width: 100%;
    z-index: 2
}

.titaui-dynamic-item__content-show-more:hover {
    color: #5c8eff;
    cursor: pointer
}
.titaui-dynamic-project-item {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 8px 0 rgba(127,145,180,.1);
    box-sizing: border-box;
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei;
    overflow: hidden
}

.titaui-dynamic-project-item__content {
    align-items: center;
    background: rgba(98,77,255,.06);
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    height: 68px;
    margin-left: 88px;
    padding: 8px 12px
}

.titaui-dynamic-project-item__content-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden
}



.titaui-dynamic-item-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 26px 0 0 24px
}

.titaui-dynamic-item-header-left {
    align-items: center;
    display: flex;
    flex: 1;
    overflow: hidden
}
.titaui-dynamic-item-header-right {
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    font-size: 24px;
    height: 24px;
    justify-content: center;
    transform: rotate(90deg);
    width: 24px
}

.titaui-dynamic-item-header-right:hover {
    background: #f0f4fa
}

.titaui-dynamic-item-header-desc {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 12px;
    overflow: hidden
}

.titaui-dynamic-item-header-desc-title {
    align-items: center;
    display: flex;
    justify-content: space-between
}

.titaui-dynamic-item-header-desc-title-left {
    align-items: center;
    display: inline-flex;
    flex: 1;
    margin-right: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all
}

.titaui-dynamic-item-header-desc-user {
    color: #3f4755;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px
}

.titaui-dynamic-item-header-desc-user--not-link:hover {
    color: #3f4755!important;
    cursor: default!important
}

.titaui-dynamic-item-header-desc-time {
    color: #89919f;
    font-size: 12px;
    line-height: 18px;
    margin-top: 4px
}

.titaui-dynamic-item-header-desc-avatar:hover {
    cursor: pointer
}

.titaui-dynamic-item-header-desc-avatar--not-link:hover {
    cursor: default!important
}

.titaui-dynamic-item-header__more-operate {
    color: #89919f;
    font-size: 14px;
    line-height: 22px
}

.titaui-dynamic-item-header__more-operate-selection {
    align-items: center;
    display: flex;
    height: 32px
}

.titaui-dynamic-item-header__more-operate-selection-icon {
    margin-right: 10px
}

.titaui-dynamic-assessment-item {
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei
}

.titaui-dynamic-assessment-item__content {
    align-items: center;
    background: rgba(246,189,22,.1);
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    height: 64px;
    margin-left: 88px;
    padding: 8px 12px
}

.titaui-dynamic-assessment-item__content-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden
}

.titaui-dynamic-assessment-item__content-title {
    align-items: center;
    color: #3f4755;
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    word-break: keep-all
}

.titaui-dynamic-assessment-item__content-title-type {
    align-items: center;
    background: rgba(246,189,22,.6);
    border-radius: 18px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    justify-content: center;
    line-height: 20px;
    margin-right: 8px;
    padding: 0 6px
}

.titaui-dynamic-assessment-item__content-title-text {
    cursor: pointer;
    flex: 1;
    overflow: hidden
}

.titaui-dynamic-assessment-item__content-title-text:hover {
    color: #2879ff
}

.titaui-dynamic-item-header-left .titaui-pc-avator-container {
    align-items: center;
    display: inline-flex
}
.titaui-pc-avator-container,.titaui-pc-avator-wrapper {
    display: inline-block;
    position: relative
}

.titaui-pc-avator-wrapper {
    border-radius: 50%;
    overflow: hidden
}

.titaui-pc-avatar--text {
    color: #fff;
    display: flex;
    font-size: 12px;
    height: 100%;
    line-height: 1;
    overflow: hidden;
    text-align: center;
    width: 100%
}

.titaui-pc-avatar--text,.titaui-pc-avator__delete-wrapper {
    align-items: center;
    border-radius: 50%;
    justify-content: center
}

.titaui-pc-avator__delete-wrapper {
    background: #f0f2f5;
    box-shadow: 0 2px 4px 0 rgba(127,145,180,.2);
    display: none;
    height: 14px;
    position: absolute;
    right: 0;
    top: -2px;
    width: 14px;
    z-index: 1
}

.titaui-pc-avator__delete-wrapper:hover {
    cursor: pointer
}

.titaui-pc-avator--enter {
    display: flex
}


.titaui-dynamic-item-header-right {
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    font-size: 24px;
    height: 24px;
    justify-content: center;
    transform: rotate(90deg);
    width: 24px
}

.titaui-dynamic-item-header-right:hover {
    background: #f0f4fa
}

.titaui-dynamic-item-header-desc {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 12px;
    overflow: hidden
}

.titaui-dynamic-item-header-desc-title {
    align-items: center;
    display: flex;
    justify-content: space-between
}

.titaui-dynamic-item-header-desc-title-left {
    align-items: center;
    display: inline-flex;
    flex: 1;
    margin-right: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all
}

  .tooltip-text-wrapper {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    white-space: nowrap;
    word-break: keep-all
}

.tooltip-text-wrapper--hidden {
    left: 0;
    position: absolute;
    visibility: hidden
}

.titaui-dynamic-task-item__operate-desc-wrapper {
    display: flex
}

.titaui-dynamic-project-item {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 8px 0 rgba(127,145,180,.1);
    box-sizing: border-box;
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei;
    overflow: hidden
}

.titaui-dynamic-project-item__content {
    align-items: center;
    background: rgba(98,77,255,.06);
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    height: 68px;
    margin-left: 88px;
    padding: 8px 12px
}

.titaui-dynamic-project-item__content-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden
}

.titaui-dynamic-project-item__content-status-icon {
    font-size: 14px
}

.titaui-dynamic-project-item__content-status-and-time-range {
    align-items: center;
    color: #89919f;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px
}

.titaui-dynamic-project-item__content-status-text {
    margin-left: 4px;
    margin-right: 20px
}

.titaui-dynamic-project-item__content-time-range {
    margin-left: 4px
}

.titaui-dynamic-project-item__content-title {
    align-items: center;
    color: #3f4755;
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    word-break: keep-all
}

.titaui-dynamic-project-item__content-title-type {
    align-items: center;
    background: rgba(98,77,255,.5);
    border-radius: 18px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    justify-content: center;
    line-height: 20px;
    margin-right: 8px;
    padding: 0 6px
}

.titaui-dynamic-project-item__content-title-text {
    cursor: pointer;
    flex: 1;
    overflow: hidden
}

.titaui-dynamic-project-item__content-title-text:hover {
    color: #2879ff
}

.titaui-dynamic-project-item__content-title {
    align-items: center;
    color: #3f4755;
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    word-break: keep-all
}
.titaui-dynamic-project-item__content-title {
    align-items: center;
    color: #3f4755;
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    word-break: keep-all
}
.titaui-dynamic-item-header-desc-user {
    color: #3f4755;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px
}

.titaui-dynamic-item-header-desc-user--not-link:hover {
    color: #3f4755!important;
    cursor: default!important
}

.titaui-dynamic-item-header-desc-time {
    color: #89919f;
    font-size: 12px;
    line-height: 18px;
    margin-top: 4px
}

.titaui-dynamic-item-header-desc-avatar:hover {
    cursor: pointer
}

.titaui-dynamic-item-header-desc-avatar--not-link:hover {
    cursor: default!important
}

.titaui-dynamic-item-header__more-operate {
    color: #89919f;
    font-size: 14px;
    line-height: 22px
}

.titaui-dynamic-item-header__more-operate-selection {
    align-items: center;
    display: flex;
    height: 32px
}

.titaui-dynamic-item-header__more-operate-selection-icon {
    margin-right: 10px
}

.titaui-dynamic-assessment-item {
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei
}

.titaui-dynamic-assessment-item__content {
    align-items: center;
    background: rgba(246,189,22,.1);
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    height: 64px;
    margin-left: 88px;
    padding: 8px 12px
}

.titaui-dynamic-assessment-item__content-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden
}

.titaui-dynamic-assessment-item__content-title {
    align-items: center;
    color: #3f4755;
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    word-break: keep-all
}

.titaui-dynamic-assessment-item__content-title-type {
    align-items: center;
    background: rgba(246,189,22,.6);
    border-radius: 18px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    justify-content: center;
    line-height: 20px;
    margin-right: 8px;
    padding: 0 6px
}

.titaui-dynamic-assessment-item__content-title-text {
    cursor: pointer;
    flex: 1;
    overflow: hidden
}

.titaui-dynamic-assessment-item__content-title-text:hover {
    color: #2879ff
}

.titaui-dynamic-assessment-item__infos {
    align-items: center;
    color: #89919f;
    display: flex;
    font-size: 12px;
    line-height: 18px
}

.titaui-dynamic-assessment-item__infos-icon {
    font-size: 14px;
    margin-right: 4px
}

.titaui-dynamic-assessment-item__infos-step-name {
    margin-right: 14px
}

.titaui-dynamic-assessment-item__operate-desc {
    color: #89919f;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    overflow: hidden
}

.titaui-dynamic-project-item__content {
    align-items: center;
    background: rgba(98,77,255,.06);
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    height: 68px;
    margin-left: 88px;
    padding: 8px 12px
}

.titaui-dynamic-project-item__content-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden
}

.titaui-dynamic-project-item__content-status-icon {
    font-size: 14px
}

.titaui-dynamic-project-item__content-status-and-time-range {
    align-items: center;
    color: #89919f;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px
}

.titaui-dynamic-project-item__content-status-text {
    margin-left: 4px;
    margin-right: 20px
}

.titaui-dynamic-project-item__content-time-range {
    margin-left: 4px
}

.titaui-dynamic-project-item__content-title {
    align-items: center;
    color: #3f4755;
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    word-break: keep-all
}

.titaui-dynamic-project-item__content-title-type {
    align-items: center;
    background: rgba(98,77,255,.5);
    border-radius: 18px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    justify-content: center;
    line-height: 20px;
    margin-right: 8px;
    padding: 0 6px
}


.titaui-dynamic-task-item__content-title-priority {
    height: 26px;
    margin-right: 4px;
    width: 18px
}

.titaui-dynamic-task-item__object-name {
    color: #2879ff;
    font-size: 14px;
    line-height: 24px;
    margin: 0 4px;
    overflow: hidden
}

.titaui-dynamic-task-item__object-name:hover {
    color: #5c8eff;
    cursor: pointer
}

.titaui-dynamic-task-item__operate-desc {
    color: #89919f;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    overflow: hidden
}

.titaui-dynamic-task-item__operate-desc-wrapper {
    display: flex
}

.titaui-dynamic-project-item {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 8px 0 rgba(127,145,180,.1);
    box-sizing: border-box;
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei;
    overflow: hidden
}

.titaui-dynamic-project-item__content {
    align-items: center;
    background: rgba(98,77,255,.06);
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    height: 68px;
    margin-left: 88px;
    padding: 8px 12px
}

.titaui-dynamic-project-item__content-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden
}

.titaui-dynamic-project-item__content-status-icon {
    font-size: 14px
}

.titaui-dynamic-project-item__content-title-text:hover {
    color: #2879ff
}
.titaui-dynamic-task-item__content {
    align-items: center;
    background: rgba(40,121,255,.06);
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    height: 64px;
    margin-left: 88px;
    padding: 8px 12px
}

.titaui-dynamic-task-item__content-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden
}

.titaui-dynamic-task-item__content-status-icon {
    font-size: 14px
}
.titaui-dynamic-project-item__content-title-priority {
    height: 26px;
    margin-right: 4px;
    width: 18px
}

.titaui-dynamic-project-item__operate-desc {
    color: #89919f;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    overflow: hidden
}
.titaui-dynamic-task-item__content-time-range {
    margin-left: 4px
}

.titaui-dynamic-task-item__content-title {
    align-items: center;
    color: #3f4755;
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    word-break: keep-all
}

.titaui-dynamic-task-item__content-title-type {
    align-items: center;
    background: rgba(92,142,255,.5);
    border-radius: 18px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    justify-content: center;
    line-height: 20px;
    margin-right: 8px;
    padding: 0 6px
}

.titaui-dynamic-task-item__content-title-text {
    cursor: pointer;
    flex: 1;
    overflow: hidden
}

.titaui-dynamic-task-item__content-title-text:hover {
    color: #2879ff
}

.titaui-dynamic-task-item__content-title-priority {
    height: 26px;
    margin-right: 4px;
    width: 18px
}

.titaui-dynamic-task-item__object-name {
    color: #2879ff;
    font-size: 14px;
    line-height: 24px;
    margin: 0 4px;
    overflow: hidden
}

.titaui-dynamic-task-item__object-name:hover {
    color: #5c8eff;
    cursor: pointer
}

.titaui-dynamic-task-item__operate-desc {
    color: #89919f;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    overflow: hidden
}

.titaui-dynamic-task-item__operate-desc-wrapper {
    display: flex
}

.titaui-dynamic-project-item {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 8px 0 rgba(127,145,180,.1);
    box-sizing: border-box;
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei;
    overflow: hidden
}

.titaui-dynamic-project-item__content {
    align-items: center;
    background: rgba(98,77,255,.06);
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    height: 68px;
    margin-left: 88px;
    padding: 8px 12px
}

.titaui-dynamic-project-item__content-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden
}

.titaui-dynamic-project-item__content-status-icon {
    font-size: 14px
}

.titaui-dynamic-project-item__content-status-and-time-range {
    align-items: center;
    color: #89919f;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px
}

.titaui-dynamic-project-item__content-status-text {
    margin-left: 4px;
    margin-right: 20px
}

.titaui-dynamic-project-item__content-time-range {
    margin-left: 4px
}

.titaui-dynamic-project-item__content-title {
    align-items: center;
    color: #3f4755;
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    word-break: keep-all
}

.titaui-dynamic-project-item__content-title-type {
    align-items: center;
    background: rgba(98,77,255,.5);
    border-radius: 18px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    justify-content: center;
    line-height: 20px;
    margin-right: 8px;
    padding: 0 6px
}

.titaui-dynamic-project-item__content-title-text {
    cursor: pointer;
    flex: 1;
    overflow: hidden
}

.titaui-dynamic-project-item__content-title-text:hover {
    color: #2879ff
}

.titaui-dynamic-project-item__content-title-priority {
    height: 26px;
    margin-right: 4px;
    width: 18px
}

.titaui-dynamic-project-item__operate-desc {
    color: #89919f;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    overflow: hidden
}
.titaui-dynamic-kr-item {
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei
}

.titaui-dynamic-kr-item__content {
    align-items: center;
    background: rgba(90,216,166,.1);
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    margin-left: 88px;
    padding: 8px 12px
}

.titaui-dynamic-kr-item__content-title {
    align-items: center;
    color: #3f4755;
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    overflow: hidden;
    white-space: nowrap;
    word-break: keep-all
}

.titaui-dynamic-kr-item__content-title-type {
    align-items: center;
    background: rgba(90,216,166,.5);
    border-radius: 18px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    justify-content: center;
    line-height: 20px;
    margin-right: 8px;
    padding: 0 6px
}

.titaui-dynamic-kr-item__content-title-text {
    cursor: pointer;
    flex: 1;
    overflow: hidden
}

.titaui-dynamic-kr-item__content-title-text:hover {
    color: #2879ff
}

.titaui-dynamic-kr-item__object-name {
    color: #2879ff;
    font-size: 14px;
    line-height: 24px;
    margin: 0 4px;
    overflow: hidden
}

.titaui-dynamic-kr-item__object-name:hover {
    color: #5c8eff;
    cursor: pointer
}

.titaui-dynamic-kr-item__operate-desc {
    color: #89919f;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    overflow: hidden
}

.titaui-dynamic-kr-item__operate-desc-wrapper {
    display: flex
}

.titaui-dynamic-reward-item {
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei
}

.titaui-dynamic-reward-item__content {
    margin-left: 88px
}

.titaui-dynamic-reward-item__title {
    align-items: center;
    display: flex;
    overflow: hidden
}

.titaui-dynamic-reward-item__description {
    color: #3f4755;
    display: flex;
    font-size: 14px;
    line-height: 22px
}

.titaui-dynamic-reward-item__description-quoto {
    height: 12px;
    width: 12px
}

.titaui-dynamic-reward-item__description-quoto--left {
    margin-right: 8px;
    margin-top: -6px
}

.titaui-dynamic-reward-item__description-quoto--right {
    margin-left: 8px;
    margin-right: 12px;
    margin-top: 4px
}

.titaui-dynamic-reward-item__status-left {
    margin-left: 12px
}

.titaui-dynamic-reward-item__percent-left {
    margin-left: 8px
}

.titaui-dynamic-reward-item__reward-tag {
    border-radius: 12px;
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
    margin-right: 8px;
    padding: 1px 12px
}

.titaui-dynamic-reward-item__operate-desc {
    color: #89919f;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    overflow: hidden
}

.titaui-dynamic-reward-item-desc-operate-type {
    align-items: center;
    border-radius: 10px 10px 10px 2px;
    color: #fff;
    display: inline-flex;
    font-size: 12px;
    height: 18px;
    justify-content: center;
    line-height: 18px;
    min-width: 40px;
    padding: 0 6px;
    width: 40px
}

.titaui-dynamic-reward-item__reward-user {
    color: #3f4755;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin: 0 4px
}

.titaui-dynamic-reward-item__reward-user:hover {
    color: #2879ff;
    cursor: pointer
}

.titaui-dynamic-reward-item__reward-user--not-link:hover {
    color: #3f4755!important;
    cursor: default!important
}

.titaui-dynamic-reward-item__reward-img {
    margin-left: 8px;
    width: 25px
}

.titaui-dynamic-milestone-item {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 8px 0 rgba(127,145,180,.1);
    box-sizing: border-box;
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei;
    overflow: hidden
}

.titaui-dynamic-milestone-item__content {
    align-items: center;
    background: rgba(98,77,255,.06);
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    height: 78px;
    margin-left: 88px;
    padding: 8px 12px
}

.titaui-dynamic-milestone-item__content-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden
}

.titaui-dynamic-milestone-item__content-status-icon {
    font-size: 14px
}

.titaui-dynamic-milestone-item__content-status-and-time-range {
    align-items: center;
    color: #89919f;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px
}

.titaui-dynamic-milestone-item__content-status-text {
    margin-left: 4px;
    margin-right: 20px
}

.titaui-dynamic-milestone-item__content-time-range {
    margin-left: 4px
}

.titaui-dynamic-milestone-item__content-title {
    align-items: center;
    color: #3f4755;
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    word-break: keep-all
}

.titaui-dynamic-milestone-item__content-title-type {
    align-items: center;
    background: rgba(98,77,255,.5);
    border-radius: 18px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    justify-content: center;
    line-height: 20px;
    margin-right: 8px;
    padding: 0 6px
}

.titaui-dynamic-milestone-item__content-title-text {
    cursor: pointer;
    flex: 1;
    overflow: hidden
}

.titaui-dynamic-milestone-item__content-title-text:hover {
    color: #2879ff
}

.titaui-dynamic-milestone-item__content-title-priority {
    height: 26px;
    margin-right: 4px;
    width: 18px
}

.titaui-dynamic-milestone-item__object-name {
    color: #2879ff;
    font-size: 14px;
    line-height: 24px;
    margin: 0 4px;
    overflow: hidden
}

.titaui-dynamic-milestone-item__object-name:hover {
    color: #5c8eff;
    cursor: pointer
}


.titaui-dynamic-operation__operate {
    margin: 14px 24px 12px 88px
}

.titaui-dynamic-operation__operate-item {
    display: flex;
    font-size: 12px;
    line-height: 18px;
    margin-top: 8px;
    position: relative
}

.titaui-dynamic-operation__operate-item:before {
    background: rgba(164,172,185,.2);
    content: "";
    height: 100%;
    left: 8px;
    position: absolute;
    top: 16px;
    width: 1px
}

.titaui-dynamic-operation__operate-item:last-child:before {
    display: none
}

.titaui-dynamic-operation__operate-item-total {
    align-items: center;
    color: #89919f;
    display: flex;
    font-size: 12px;
    line-height: 18px
}

.titaui-dynamic-operation__operate-item-total-num {
    margin: 0 4px
}

.titaui-dynamic-operation__operate-item-total:hover {
    color: #2879ff;
    cursor: pointer
}

.titaui-dynamic-operation__operate-item-left {
    display: inline-flex;
    margin-right: 12px
}

.titaui-dynamic-operation__operate-item-right {
    flex: 1
}

.titaui-dynamic-operation__operate-icon {
    height: 18px;
    margin-right: 8px;
    width: 18px
}

.titaui-dynamic-operation__operate-time {
    color: #89919f
}

.titaui-dynamic-operation__operate-user {
    color: #3f4755;
    margin-right: 8px
}

.titaui-dynamic-operation__operate-content {
    color: #6f7886;
    white-space: pre-wrap
}




.titaui-dynamic-create-o-item__content {
    background: rgba(40,121,255,.06);
    border-radius: 18px;
    display: flex;
    margin-left: 88px;
    padding: 16px
}

.titaui-dynamic-create-o-item__content-progress-text {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%)
}

.titaui-dynamic-create-o-item__content-right {
    flex: 1;
    margin-left: 12px;
    overflow: hidden
}

.titaui-dynamic-create-o-item__content-right-title {
    box-sizing: border-box;
    color: #3f4755;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    overflow: hidden;
    white-space: nowrap;
    word-break: keep-all;
    z-index: 2
}

.titaui-dynamic-create-o-item__content-right-title-text {
    cursor: pointer;
    flex: 1;
    overflow: hidden
}

.titaui-dynamic-create-o-item__content-right-title-text:hover {
    color: #2879ff!important
}

.titaui-dynamic-create-o-item__content-right-infos-wrapper {
    margin-top: 6px
}

.titaui-dynamic-create-o-item__okr {
    align-items: center;
    color: #89919f;
    display: flex;
    font-size: 12px;
    line-height: 18px
}

.titaui-dynamic-create-o-item__okr-type:after {
    background: linear-gradient(180deg,rgba(191,199,213,0),#bfc7d5 50%,rgba(240,242,245,0));
    content: "";
    display: inline-block;
    height: 14px;
    margin: 0 8px;
    vertical-align: text-top;
    width: 1px
}

.titaui-dynamic-create-o-item__okr-krs {
    margin-top: 12px
}

.titaui-dynamic-create-o-item__kr {
    display: flex;
    margin-top: 6px
}

.titaui-dynamic-create-o-item__kr-order {
    color: #89919f;
    font-size: 12px;
    line-height: 18px;
    margin-right: 4px;
    margin-top: 3px;
    word-break: keep-all
}

.titaui-dynamic-create-o-item__kr-name {
    color: #3f4755;
    font-size: 14px;
    line-height: 22px
}

.titaui-dynamic-create-o-item__kr-name:hover {
    color: #2879ff;
    cursor: pointer
}

.titaui-dynamic-create-o-item__operate-desc {
    color: #89919f;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    overflow: hidden
}

.titaui-dynamic-task-item {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 8px 0 rgba(127,145,180,.1);
    box-sizing: border-box;
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei;
    overflow: hidden
}

.titaui-dynamic-task-item__content {
    align-items: center;
    background: rgba(40,121,255,.06);
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    height: 64px;
    margin-left: 88px;
    padding: 8px 12px
}

.titaui-dynamic-task-item__content-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden
}

.titaui-dynamic-task-item__content-status-icon {
    font-size: 14px
}

.titaui-dynamic-task-item__content-status-and-time-range {
    align-items: center;
    color: #89919f;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px
}

.titaui-dynamic-task-item__content-status-text {
    margin-left: 4px;
    margin-right: 20px
}

.titaui-dynamic-task-item__content-time-range {
    margin-left: 4px
}

.titaui-dynamic-task-item__content-title {
    align-items: center;
    color: #3f4755;
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    word-break: keep-all
}

.titaui-dynamic-task-item__content-title-type {
    align-items: center;
    background: rgba(92,142,255,.5);
    border-radius: 18px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    justify-content: center;
    line-height: 20px;
    margin-right: 8px;
    padding: 0 6px
}

.titaui-dynamic-task-item__content-title-text {
    cursor: pointer;
    flex: 1;
    overflow: hidden
}

.titaui-dynamic-task-item__content-title-text:hover {
    color: #2879ff
}

.titaui-dynamic-task-item__content-title-priority {
    height: 26px;
    margin-right: 4px;
    width: 18px
}

.titaui-dynamic-task-item__object-name {
    color: #2879ff;
    font-size: 14px;
    line-height: 24px;
    margin: 0 4px;
    overflow: hidden
}

.titaui-dynamic-task-item__object-name:hover {
    color: #5c8eff;
    cursor: pointer
}

.titaui-dynamic-task-item__operate-desc {
    color: #89919f;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    overflow: hidden
}

.titaui-dynamic-task-item__operate-desc-wrapper {
    display: flex
}

.titaui-dynamic-project-item {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 8px 0 rgba(127,145,180,.1);
    box-sizing: border-box;
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei;
    overflow: hidden
}

.titaui-dynamic-project-item__content {
    align-items: center;
    background: rgba(98,77,255,.06);
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    height: 68px;
    margin-left: 88px;
    padding: 8px 12px
}

.titaui-dynamic-project-item__content-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden
}

.titaui-dynamic-project-item__content-status-icon {
    font-size: 14px
}

.titaui-dynamic-project-item__content-status-and-time-range {
    align-items: center;
    color: #89919f;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px
}

.titaui-dynamic-project-item__content-status-text {
    margin-left: 4px;
    margin-right: 20px
}

.titaui-dynamic-project-item__content-time-range {
    margin-left: 4px
}
.titaui-progress-circle {
    align-items: center;
    display: inline-flex;
    position: relative
}

.titaui-progress-circle .text-content {
    color: #89919f;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis
}

.okr-detail__tabpanel-content {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 52px
}

.okr-detail__tabpanel-content-inner {
    padding-top: 20px
}

.base-info-o-desc-wrapper {
    background: #fff;
    position: relative
}

.base-info-o-desc-wrapper .o-desc__title {
    color: #141c28;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin-top: 12px;
    padding-left: 10px
}

.base-info-o-desc-wrapper .o-desc__add-desc {
    color: #2879ff;
    font-size: 14px;
    line-height: 22px
}

.base-info-o-desc-wrapper .o-desc__add-desc .o-desc__add-desc-icon {
    margin-right: 4px
}

.base-info-o-desc-wrapper .o-desc__add-desc:hover {
    cursor: pointer
}

.base-info-o-desc-wrapper .o-desc__text-area-box {
    border: 1px solid transparent;
    border-radius: 4px;
    box-sizing: border-box;
    color: #6f7886;
    cursor: pointer;
    font-size: 14px;
    line-height: 22px;
    outline: none;
    padding: 8px 10px 6px;
    position: relative;
    resize: none;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-all
}

.base-info-o-desc-wrapper .o-desc__text-area-box:hover {
    border: 1px solid #e9ecf0
}

.base-info-o-desc-wrapper .o-desc__show-more {
    bottom: 6px;
    color: #2879ff;
    cursor: pointer;
    position: absolute;
    right: 4px;
    z-index: 2
}

.base-info-o-desc-wrapper .o-desc__show-more--up {
    bottom: -28px;
    position: relative
}

.base-info-o-desc-wrapper .o-desc__show-more--down {
    background: linear-gradient(90deg,transparent 0,#fff 10px);
    padding: 0 10px
}

.base-info-o-desc-wrapper .o-desc-arrow-icon {
    position: relative;
    top: 1px
}

.base-info-o-desc-wrapper .o-desc__text-area-box-text--no-show {
    cursor: pointer;
    height: 154px;
    overflow: hidden
}

.base-info-o-desc-wrapper .o-desc__text-area-box--no-border:hover {
    border: 1px solid transparent
}

.base-info-o-desc-wrapper .o-desc__text-area {
    cursor: auto;
    height: 166px;
    max-height: 166px
}

.base-info-o-desc-wrapper .o-desc__text-area--blur {
    position: absolute;
    top: 0;
    visibility: hidden;
    z-index: -1
}
.titaui-dynamic-item-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 26px 0 0 24px
}

.titaui-dynamic-item-header-left {
    align-items: center;
    display: flex;
    flex: 1;
    overflow: hidden
}

.titaui-dynamic-item-header-left .titaui-pc-avator-container {
    align-items: center;
    display: inline-flex
}

.titaui-dynamic-item-header-right {
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    font-size: 24px;
    height: 24px;
    justify-content: center;
    transform: rotate(90deg);
    width: 24px
}

.titaui-dynamic-item-header-right:hover {
    background: #f0f4fa
}

.titaui-dynamic-item-header-desc {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 12px;
    overflow: hidden
}

.titaui-dynamic-item-header-desc-title {
    align-items: center;
    display: flex;
    justify-content: space-between
}

.titaui-dynamic-item-header-desc-title-left {
    align-items: center;
    display: inline-flex;
    flex: 1;
    margin-right: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all
}

.titaui-dynamic-item-header-desc-user {
    color: #3f4755;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px
}

.titaui-dynamic-item-header-desc-user--not-link:hover {
    color: #3f4755!important;
    cursor: default!important
}


.titaui-dynamic-reply-item__content {
    margin-left: 88px
}

.titaui-dynamic-reply-item__content-title {
    display: flex;
    overflow: hidden
}

.titaui-dynamic-reply-item__detail {
    margin-top: 12px
}

.titaui-dynamic-reply-item__detail-drawback,.titaui-dynamic-reply-item__detail-gain {
    margin-bottom: 16px
}

.titaui-dynamic-reply-item__detail-drawback:last-of-type {
    margin-bottom: 0
}

.titaui-dynamic-reply-item__detail-tasks {
    margin-top: 16px
}

.titaui-dynamic-reply-item__detail-tasks-item {
    align-items: center;
    color: #6f7886;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    line-height: 22px;
    margin-top: 8px
}

.titaui-dynamic-reply-item__detail-tasks-item:before {
    background: #2879ff;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 5px;
    margin-right: 6px;
    width: 5px
}

.titaui-dynamic-reply-item__detail-tasks-item:hover {
    color: #2879ff
}

.titaui-dynamic-reply-item__detail-block-title {
    align-items: center;
    color: #3f4755;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px
}

.titaui-dynamic-reply-item__detail-block-title--sub {
    align-items: center;
    color: #3f4755;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 4px;
    margin-left: 12px;
    margin-top: 16px;
    white-space: pre-wrap
}

.titaui-dynamic-reply-item__detail-block-content {
    color: #6f7886;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 4px;
    white-space: pre-wrap
}

.titaui-dynamic-reply-item__detail-block-content:last-of-type {
    margin-bottom: 0
}

.titaui-dynamic-reply-item__detail-block-content--sub {
    margin-left: 12px
}

.titaui-dynamic-reply-item__object-name {
    color: #2879ff;
    font-size: 14px;
    line-height: 22px;
    margin: 0 4px;
    overflow: hidden
}

.titaui-dynamic-reply-item__object-name:hover {
    color: #5c8eff;
    cursor: pointer
}

.titaui-dynamic-reply-item__operate-desc {
    color: #89919f;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    overflow: hidden
}

.titaui-dynamic-reply-item__operate-desc-wrapper {
    display: flex
}

.titaui-dynamic-reply-item-desc-operate-type {
    align-items: center;
    border-radius: 10px 10px 10px 2px;
    color: #fff;
    display: inline-flex;
    font-size: 12px;
    height: 18px;
    justify-content: center;
    line-height: 18px;
    min-width: 40px;
    padding: 0 6px
}

.titaui-dynamic-report-item {
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei
}

.titaui-dynamic-report-item__content {
    word-wrap: break-word;
    line-height: 24px;
    margin-left: 88px
}

.titaui-dynamic-report-item__content-title {
    display: flex;
    overflow: hidden
}

.titaui-dynamic-report-item__content ul {
    list-style-type: disc;
    padding-left: 20px
}

.titaui-dynamic-report-item__content ol {
    list-style-type: decimal;
    padding-left: 20px
}

.titaui-dynamic-report-item__content li {
    list-style-type: inherit;
    margin: 5px 0
}

.titaui-dynamic-report-item__content table {
    margin: 5px 0
}

.titaui-dynamic-report-item__content table td {
    border: 1px solid #999;
    height: 20px;
    min-width: 50px;
    padding: 3px 5px
}

.titaui-dynamic-report-item__content p {
    margin: 5px 0
}

.titaui-dynamic-report-item__content blockquote {
    border-left: 4px solid #e3ecf1;
    display: block;
    font-size: 12px;
    padding: 5px
}

.titaui-dynamic-report-item__content li,.titaui-dynamic-report-item__content p {
    line-height: 1.7
}

.titaui-dynamic-report-item__content a {
    word-wrap: break-word;
    word-break: break-all
}

.titaui-dynamic-report-item__content pre {
    font-size: .8em;
    margin: 5px 0;
    padding: 10px
}

.titaui-dynamic-report-item__content pre code {
    line-height: 1.5
}

.titaui-dynamic-report-item__summary {
    margin-bottom: 16px;
    margin-top: 12px
}

.titaui-dynamic-report-item__block-title {
    align-items: center;
    color: #3f4755;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 8px
}

.titaui-dynamic-report-item__block-title:before {
    background: #2879ff;
    border-radius: 2px;
    content: "";
    display: inline-block;
    height: 12px;
    margin-right: 4px;
    width: 4px
}

.titaui-dynamic-report-item__content-text {
    margin-left: 8px
}

.titaui-dynamic-report-item__complete-type {
    align-items: center;
    border-radius: 10px 10px 10px 0;
    display: flex;
    font-size: 12px;
    line-height: 18px;
    margin-left: 8px;
    padding: 1px 6px
}
.titaui-dynamic-title,.titaui-dynamic-title__type {
    align-items: center;
    border-radius: 18px;
    display: flex;
    justify-content: center
}
.titaui-dynamic-title{
	padding-right:10px;
}
.titaui-dynamic-title__type {
    color: #fff;
    margin-right: 6px;
    padding: 1px 6px
}

.titaui-dynamic-title__content {
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    word-break: keep-all;
    z-index: 2
}

.titaui-dynamic-title__content-text {
    cursor: pointer;
    flex: 1;
    overflow: hidden
}

.titaui-dynamic-title__content-text:hover {
    opacity: .6
}

.titaui-dynamic-progress-item__obj-wrapper {
    display: flex
}

.titaui-dynamic-progress-item__operate-desc {
    color: #89919f;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    overflow: hidden
}

.titaui-dynamic-create-o-item {
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei
}

.titaui-dynamic-create-o-item__content {
    background: rgba(40,121,255,.06);
    border-radius: 18px;
    display: flex;
    margin-left: 88px;
    padding: 16px
}

.titaui-dynamic-create-o-item__content-progress-text {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%)
}

.titaui-dynamic-create-o-item__content-right {
    flex: 1;
    margin-left: 12px;
    overflow: hidden
}

.titaui-dynamic-create-o-item__content-right-title {
    box-sizing: border-box;
    color: #3f4755;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    overflow: hidden;
    white-space: nowrap;
    word-break: keep-all;
    z-index: 2
}

.titaui-dynamic-create-o-item__content-right-title-text {
    cursor: pointer;
    flex: 1;
    overflow: hidden
}

.titaui-dynamic-create-o-item__content-right-title-text:hover {
    color: #2879ff!important
}

.titaui-dynamic-create-o-item__content-right-infos-wrapper {
    margin-top: 6px
}

.titaui-dynamic-create-o-item__okr {
    align-items: center;
    color: #89919f;
    display: flex;
    font-size: 12px;
    line-height: 18px
}

.titaui-dynamic-create-o-item__okr-type:after {
    background: linear-gradient(180deg,rgba(191,199,213,0),#bfc7d5 50%,rgba(240,242,245,0));
    content: "";
    display: inline-block;
    height: 14px;
    margin: 0 8px;
    vertical-align: text-top;
    width: 1px
}

.titaui-dynamic-create-o-item__okr-krs {
    margin-top: 12px
}

.titaui-dynamic-create-o-item__kr {
    display: flex;
    margin-top: 6px
}

.titaui-dynamic-create-o-item__kr-order {
    color: #89919f;
    font-size: 12px;
    line-height: 18px;
    margin-right: 4px;
    margin-top: 3px;
    word-break: keep-all
}

.titaui-dynamic-create-o-item__kr-name {
    color: #3f4755;
    font-size: 14px;
    line-height: 22px
}

.titaui-dynamic-create-o-item__kr-name:hover {
    color: #2879ff;
    cursor: pointer
}

.titaui-dynamic-create-o-item__operate-desc {
    color: #89919f;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    overflow: hidden
}

.titaui-dynamic-task-item {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 8px 0 rgba(127,145,180,.1);
    box-sizing: border-box;
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei;
    overflow: hidden
}

.titaui-dynamic-task-item__content {
    align-items: center;
    background: rgba(40,121,255,.06);
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    height: 64px;
    margin-left: 88px;
    padding: 8px 12px
}

.titaui-dynamic-task-item__content-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden
}

.titaui-dynamic-task-item__content-status-icon {
    font-size: 14px
}

.titaui-dynamic-task-item__content-status-and-time-range {
    align-items: center;
    color: #89919f;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px
}

.titaui-dynamic-task-item__content-status-text {
    margin-left: 4px;
    margin-right: 20px
}

.titaui-dynamic-task-item__content-time-range {
    margin-left: 4px
}

.titaui-dynamic-task-item__content-title {
    align-items: center;
    color: #3f4755;
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    word-break: keep-all
}

.titaui-dynamic-task-item__content-title-type {
    align-items: center;
    background: rgba(92,142,255,.5);
    border-radius: 18px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    justify-content: center;
    line-height: 20px;
    margin-right: 8px;
    padding: 0 6px
}

.titaui-dynamic-task-item__content-title-text {
    cursor: pointer;
    flex: 1;
    overflow: hidden
}

.titaui-dynamic-task-item__content-title-text:hover {
    color: #2879ff
}

.titaui-dynamic-task-item__content-title-priority {
    height: 26px;
    margin-right: 4px;
    width: 18px
}
.titaui-dynamic-progress-item__status {
    margin-top: 12px
}

.titaui-dynamic-progress-item__object-name {
    color: #2879ff;
    font-size: 14px;
    line-height: 24px;
    margin: 0 4px;
    overflow: hidden
}

.titaui-dynamic-progress-item__object-name:hover {
    color: #5c8eff;
    cursor: pointer
}

.titaui-dynamic-progress-item__obj-wrapper {
    display: flex
}

.titaui-dynamic-progress-item__operate-desc {
    color: #89919f;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    overflow: hidden
}

.titaui-dynamic-progress-item {
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei
}

.titaui-dynamic-progress-item__content {
    margin-left: 88px
}

.titaui-dynamic-progress-item__title {
    align-items: center;
    display: flex;
    overflow: hidden
}

.titaui-dynamic-progress-item__description {
    color: #3f4755;
    display: flex;
    font-size: 14px;
    line-height: 24px;
    margin-top: 4px
}

.titaui-dynamic-progress-item__description-text {
    white-space: pre-wrap
}

.titaui-dynamic-progress-item__status {
    margin-top: 12px
}

.titaui-dynamic-progress-item__object-name {
    color: #2879ff;
    font-size: 14px;
    line-height: 24px;
    margin: 0 4px;
    overflow: hidden
}

.titaui-dynamic-progress-item__object-name:hover {
    color: #5c8eff;
    cursor: pointer
}

.titaui-dynamic-progress-item__obj-wrapper {
    display: flex
}

.titaui-dynamic-progress-item__operate-desc {
    color: #89919f;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    overflow: hidden
}

.titaui-dynamic-create-o-item {
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei
}

.titaui-dynamic-create-o-item__content {
    background: rgba(40,121,255,.06);
    border-radius: 18px;
    display: flex;
    margin-left: 88px;
    padding: 16px
}

.titaui-dynamic-create-o-item__content-progress-text {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%)
}

.titaui-dynamic-create-o-item__content-right {
    flex: 1;
    margin-left: 12px;
    overflow: hidden
}

.titaui-dynamic-create-o-item__content-right-title {
    box-sizing: border-box;
    color: #3f4755;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    overflow: hidden;
    white-space: nowrap;
    word-break: keep-all;
    z-index: 2
}

.titaui-dynamic-create-o-item__content-right-title-text {
    cursor: pointer;
    flex: 1;
    overflow: hidden
}

.titaui-dynamic-create-o-item__content-right-title-text:hover {
    color: #2879ff!important
}

.titaui-dynamic-create-o-item__content-right-infos-wrapper {
    margin-top: 6px
}

.titaui-dynamic-create-o-item__okr {
    align-items: center;
    color: #89919f;
    display: flex;
    font-size: 12px;
    line-height: 18px
}

.titaui-dynamic-create-o-item__okr-type:after {
    background: linear-gradient(180deg,rgba(191,199,213,0),#bfc7d5 50%,rgba(240,242,245,0));
    content: "";
    display: inline-block;
    height: 14px;
    margin: 0 8px;
    vertical-align: text-top;
    width: 1px
}

.titaui-dynamic-create-o-item__okr-krs {
    margin-top: 12px
}

.titaui-dynamic-create-o-item__kr {
    display: flex;
    margin-top: 6px
}

.titaui-dynamic-create-o-item__kr-order {
    color: #89919f;
    font-size: 12px;
    line-height: 18px;
    margin-right: 4px;
    margin-top: 3px;
    word-break: keep-all
}

.titaui-dynamic-create-o-item__kr-name {
    color: #3f4755;
    font-size: 14px;
    line-height: 22px
}

.titaui-dynamic-create-o-item__kr-name:hover {
    color: #2879ff;
    cursor: pointer
}

.titaui-dynamic-create-o-item__operate-desc {
    color: #89919f;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    overflow: hidden
}
.titaui-dynamic-percent {
    color: #3f4755;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px
}

.titaui-dynamic-percent__grow {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    margin-left: 4px
}

.titaui-dynamic-percent__grow--increase {
    color: #5ad8a6
}

.titaui-dynamic-percent__grow--decrease {
    color: #f05e5e
}

.titaui-dynamic-obj-status {
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    margin-right: 8px
}

.titaui-dynamic-progress-item {
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei
}

.titaui-dynamic-progress-item__content {
    margin-left: 88px
}

.titaui-dynamic-progress-item__title {
    align-items: center;
    display: flex;
    overflow: hidden
}

.titaui-dynamic-progress-item__description {
    color: #3f4755;
    display: flex;
    font-size: 14px;
    line-height: 24px;
    margin-top: 4px
}

.titaui-dynamic-progress-item__description-text {
    white-space: pre-wrap
}

.titaui-dynamic-progress-item__status {
    margin-top: 12px
}

.titaui-dynamic-progress-item__object-name {
    color: #2879ff;
    font-size: 14px;
    line-height: 24px;
    margin: 0 4px;
    overflow: hidden
}

.titaui-dynamic-progress-item__object-name:hover {
    color: #5c8eff;
    cursor: pointer
}

.titaui-dynamic-progress-item__obj-wrapper {
    display: flex
}

.titaui-dynamic-progress-item__operate-desc {
    color: #89919f;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    overflow: hidden
}

.titaui-dynamic-create-o-item {
    font-family: -apple-system,Arial,Verdana,Helvetica Neue,Helvetica,sans-serif,PingFang SC,Microsoft YaHei
}

</style>
